@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/css_files/Satoshi-Regular.woff') format('woff'),
    url('./assets/css_files/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/css_files/Satoshi-Regular.ttf') format('ttf'),
    url('./assets/css_files/Satoshi-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@layer components {
  /* .button {
    @apply mt-3 [background:linear-gradient(90deg, rgb(145, 94, 255)_0%, rgb(0, 126, 236)_100%)] cursor-pointer rounded-[40px] hover:font-bold hover:opacity-75 transition-opacity hover:text-blue-300 hover:text-shadow-blue-glow
  } */

  .button {
    @apply mt-3 bg-gradient-to-r from-purple-600 to-blue-500 cursor-pointer rounded-[40px] hover:font-bold hover:opacity-75 transition-opacity hover:text-blue-300 hover:text-shadow-blue-glow;
  }

  .background-screen {
    @apply bg-slate-950 w-full max-h-full flex flex-col items-center justify-center bg-path-pattern text-white
  }

  .input-field {
    @apply shadow appearance-none border rounded-xl w-full py-3 px-4 text-gray-900 leading-tight focus:outline-none my-2 placeholder-black
  }

  /* .outer-box {
    @apply p-[1.5px] rounded-2xl opacity-85 [background:linear-gradient(90.13deg, rgb(145, 94, 255)_1.9%, rgb(0, 126, 236)_97.5%)]
  } */

  .outer-box {
    @apply p-[1.5px] rounded-2xl opacity-85 bg-gradient-to-r from-purple-600 to-blue-500;
  }


  .inner-box {
    @apply bg-[#151030] p-3 lg:p-5 rounded-2xl
  }

  .button-content {
    @apply no-underline text-white px-2 py-1 mx-2 tracking-[0] leading-[normal] hover:font-bold cursor-pointer hover:text-blue-300 hover:text-shadow-blue-glow
  }
}

* {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Roboto", sans-serif;
}

body {
  @apply m-0 p-0
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  height: 100%;
}


h2{
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 48px;
}

p,
a,
li {}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.popup {
  display: flex;
  align-items: center;
}

.popup-heading {
  color: #333B69;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.8);
}

a {
  text-decoration: none;
}

/* CSS Code for rotating image */
@keyframes rotate-earth {
  from {
    transform: rotate3d(0, 1, 0.3, 0deg);
    /* Slight tilt to simulate Earth's axis */
    /*transform: rotateX(0deg); */
  }

  to {
    transform: rotate3d(1, 1, 0.3, 360deg);

  }
}

.rotate-continuous {
  transform-style: preserve-3d;
  animation: rotate-earth 20s linear infinite;
}

textarea {
  resize: none;
}

/* Styling the scrollbar track (background) */
::-webkit-scrollbar-track {
  background: #1e2225; /* Darker background for contrast */
  border-radius: 10px;
}

/* Styling the scrollbar thumb (scrollable handle) */
::-webkit-scrollbar-thumb {
  background: #4f5d75; /* Softer dark blue-gray */
  border-radius: 10px;
  border: 3px solid #1e2225; /* Creates a separation effect */
  transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

/* Handle hover styles */
::-webkit-scrollbar-thumb:hover {
  background: #6c7a92; /* Lighter blue-gray for hover */
  border-color: #2b3136;
}

/* Styling the scrollbar width and height */
::-webkit-scrollbar {
  width: .6rem;
  height: .6rem;
}

/* Scrollbar corner (where horizontal & vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background: #151618; /* Keep the corner but match the track color */
}


.bg-shape-0 {
  background-image: url('./assets/image_files/Shape1.svg');
}

.bg-shape-1 {
  background-image: url('./assets/image_files/Shape2.svg');
}

.bg-shape-2 {
  background-image: url('./assets/image_files/Shape3.svg');
}

.css-typing h4 {
  border: 0px;
  border-right: .08em solid white;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.css-typing h4:nth-child(1) {
  width: 14ch;
  /* manually set width */
  -webkit-animation: type 2s steps(16, end);
  animation: type 2s steps(16, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing h4:nth-child(2) {
  width: 18ch;
  /* manually set width */
  opacity: 0;
  -webkit-animation: type2 2s steps(21, end);
  animation: type2 2s steps(21, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing h4:nth-child(3) {
  width: 20ch;
  /* manually set width */
  opacity: 0;
  -webkit-animation: type2 5s steps(20, end), blink .5s step-end infinite alternate;
  animation: type2 2s steps(20, end), blink .5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: .08em solid white;
  }

  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: .08em solid white;
  }

  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: .08em solid white;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: .08em solid white;
  }

  100% {
    opacity: 1;
    border: none;
  }
}


@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media (max-width:768px) {
  .css-typing {
    font-size: 17px;
  }
}


.local-container {
  width: 100%;
}

@media (min-width: 640px) {
  .local-container {
    max-width: 620px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 768px) {
  .local-container {
    max-width: 748px;
  }
}

@media (min-width: 1024px) {
  .local-container {
    max-width: 996px;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media (min-width: 1280px) {
  .local-container {
    max-width: 1260px;
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media (min-width: 1440px) {
  .local-container {
    max-width: 1420px;
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media (min-width: 1546px) {
  .local-container {
    max-width: 1516px;
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
}

.container1 {
  width: 100%;

}

@media (min-width: 640px) {
  .container1 {
    max-width: 620px;

  }
}

@media (min-width: 768px) {
  .container1 {
    max-width: 748px;
  }
}

@media (min-width: 1024px) {
  .container1 {
    max-width: 996px;

  }
}

@media (min-width: 1280px) {
  .container1 {
    max-width: 1260px;
  }
}

@media (min-width: 1440px) {
  .container1 {
    max-width: 1420px;

  }
}

@media (min-width: 1546px) {
  .container1 {
    max-width: 1516px;

  }
}

.nav-local-container {
  width: 100%;
  /* margin-right: 1rem;
  margin-left: 1rem; */
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.10);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6px);
-webkit-backdrop-filter: blur(6px);
}

@media (min-width: 640px) {
  .nav-local-container {
    max-width: 620px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 768px) {
  .nav-local-container {
    max-width: 748px;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

@media (min-width: 1024px) {
  .nav-local-container {
    max-width: 996px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
}

@media (min-width: 1280px) {
  .nav-local-container {
    max-width: 1260px;
    margin-right: 4rem;
    margin-left: 4rem;
  }
}

@media (min-width: 1440px) {
  .nav-local-container {
    max-width: 1420px;
    margin-right: 4rem;
    margin-left: 4rem;
  }
}

@media (min-width: 1546px) {
  .nav-local-container {
    max-width: 1516px;
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
}

.MuiTooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 30px;
  max-width: 500px;
  word-wrap: break-word;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: transparent !important;
}

.card {
  border-radius: 16px;
  border: 0.05px 0px 0px 0px;
  background: linear-gradient(180deg, rgba(21, 16, 48, 0.9) 49.23%, rgba(21, 16, 48, 0) 100%, rgba(21, 16, 48, 0.774) 100%);
  border: 0.05px solid;
  border-image-source: linear-gradient(270deg, #151030 0%, #241A4F 10%);
  box-shadow: 0px 4px 32px -1px #000000BF;
  margin: 8px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 20px;
  flex-grow: 1;
}

.page-background {
  background-color: #060515;
  width: 100%;
  /* max-height: 100%; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: auto;
}

.glowing-card {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: radial-gradient(circle, rgba(73, 113, 255, 0.2) 0%, rgba(111, 48, 203, 0.005) 80%);
    z-index: -1;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      filter: brightness(1);
    }

    50% {
      filter: brightness(2);
    }

    100% {
      filter: brightness(1);
    }
  }
}

input {
  accent-color: #06051580;
}

.input-field {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
}

.input-field:focus {
  outline: none;
  border-color: #e81cff;
}

.input-field::placeholder {
  opacity: 0.5;
  color: #fff;
}

.signup-button {
  display: inline-block;
  border-radius: 4px;
  background-color: #3d405b;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 17px;
  padding: 8px;
  width: 130px;
  transition: all 0.5s;
  cursor: pointer;
}

.signup-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.signup-button span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

.signup-button:hover span {
  padding-right: 15px;
}

.signup-button:hover span:after {
  opacity: 1;
  right: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color: red;
}

.input{
  @apply bg-[#222630] px-4 py-2 outline-none w-full text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040] text-base
}

.CodeMirror * {
  /*@apply text-gray-400 */
  text-align: left !important;
  font-family: "Menlo"," Monaco", "Courier New", monospace;
  font-size: 14px !important;
  /*color: rgb(156 163 175 0.5/ var(--tw-text-opacity))  #9ca3af  !important; */
  }
.CodeMirror *{
  @apply text-gray-400
}  

._container_1dwvo_15{
  @apply !w-full
}

._calendar_1dwvo_19{
  @apply !p-0 !w-full
}

._description_1fqsg_1{
  @apply !p-0 !mt-3
}

.react-calendar-heatmap .color-empty{
  fill: #161b22 !important;
}

.react-calendar-heatmap-weekday-labels{
  transform: translate(0, 14px);
}

.color-filled, .color-empty{  
  rx: 3;
  ry: 3;
}

.react-calendar-heatmap .color-scale-0 { fill: #161b22; rx:3; ry:3}
.react-calendar-heatmap .color-scale-4 { fill: #d6e685; rx:3; ry:3}
.react-calendar-heatmap .color-scale-3 { fill: #8cc665; rx:3; ry:3}
.react-calendar-heatmap .color-scale-2 { fill: #44a340; rx:3; ry:3}
.react-calendar-heatmap .color-scale-1 { fill: #1e6823; rx:3; ry:3}

.Toastify__toast-theme--dark{
  background-color: transparent !important;
  box-shadow: none !important;
}

