.arenas-homepage-container {   
    /* padding:1rem; */
    background-color: #1d1d1d;
    color:#fefefe;
    overflow: auto;
}

/* .enrl-avl-heading {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    position:sticky;
    top:0.1rem;
    bottom:0.2rem;
} */

.arena-item {
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    /* width:100%; */
    background-color: #232627;
}

/* .arena-item:hover,.active-arena {
    background-color: #fefefe8a;
    color:#232627;
} */
.arena-item a {
    width:100%;
}

.enrolled-arenas-container {
    background-color: #1d1d1d;
    border-radius:0.5rem;
    /* height:100%; */
    border:0.5px solid #fefefe1a;
    /* overflow: auto; */
}

/* .arena-title-count-container {
    display:flex;
    width:100%;
    justify-content: start;
    align-items: center;
} */

/* .arena-title-count-container p{
    display:flex;
    gap:0.2rem;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(44, 32, 18, 1);
    color:rgba(255, 159, 42, 1);
    border-radius:0.5rem;
    padding:0.2rem 0.4rem;
} */

.arena-title-count-container button {
    margin:0px;
    padding:0px;
}

/* .arenas-list-description {
    display:grid;
    grid-template-columns: 45% 50%;
    gap : 1rem;
    height:90%;
} */
.arenas-avl-list {
    overflow: auto;
}

.ind-arena-desc{
    border:0.5px solid #fefefe1a;
    background-color: #232627;
    margin:0.5rem;
    border-radius: 8px;
    height:100%;
    overflow: auto;
}
.title-line {
    /* position:sticky;
    top:0rem; */
    /* font-weight: 600; */
    /* margin-bottom: 1.1rem; */
    /* padding:0.6rem; */
    background-color: #232627;
}
.announcement-board p  {
    overflow: auto;
    padding:0.6rem;
}
.ind-arena-desc a,.join-btn {
    text-align: center;
    padding:0.2rem 0.4rem;
    background-color: #615fc48a;
    color:#fefefe;
    position:sticky;
    bottom:0.1rem;
    border-radius: 8px;
    margin:0rem 0.3rem;
}
/* .arena-reference-desc-links-container,.ind-roadmaps-container {
    display:grid;
    grid-template-columns: 60% 40%;
    height:100vh;
} */
.arena-roadmap-container {
  display: grid;
  grid-template-rows: auto 3fr;
  height: 100vh;
}

.arena-home-desc-container,.ref-links,.announcement-board,.roadmap-item-container,.roadmap-desc-container {
    border:0.5px solid #fefefe1a;
    background-color: #141718;
    margin:0.5rem 0rem;
    border-radius: 8px; 
    overflow: auto;
}
.arena-home-desc-container p , .ref-links div,.active-roadmap-onjective p,.announcement-board div {
    overflow: auto;
    /* padding:0.6rem; */

}
.references-announcements-section {
    display:grid;
    grid-template-rows: 50% 50%;
    /* width:95%; */
}

.roadmaps-container {
    display: grid;
    grid-template-columns: 25% 75%;
    gap:0.4rem;
    height:auto;
    padding: 0.4rem;
    
}
.roadmap-item {
    margin:0.2rem;
    padding:0.2rem;
    font-weight: 600;
    overflow:auto;
}
.roadmap-item:hover {
    font-weight: 800;
}
.title-row {
    background-color: transparent;
}
.arena-mode-toggle {
    background-color: #4CAF50;
    /* green background */
    border: none;
    border-radius: 12px;
    /* rounded sides */
    padding:0px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width :auto;
    padding: 0px 5px;
  }
  
  .arena-mode-toggle:hover {
    background-color: #3e8e41;
    /* darker green on hover */
  }
  
  .toggle-circle {
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    /* white circle */
    
  }
  
  .arena-mode-toggle.active.toggle-circle {
    background-color: #4CAF50;
    /* green circle when active */
  }
  .chrono-container li {
    justify-content: space-between !important;
  }
  .chrono-card {
    width : auto !important;
    flex-grow: 1;
  }
  .chrono-card button {
    padding:0px;
  }
  .chrono-card-content {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    overflow: auto;
    flex-wrap: wrap;
  }
  
  .chrono-card-content li {
    display: flex;
  }
  
  .card-left {
    justify-content: flex-end;
  }
  
  .card-right {
    justify-content: flex-start;
  }
  .gyFIuQ {
    width : auto !important;
  }
  .jjfYoe-left::before {
    background-color: transparent !important;
  }
  


  .transparent_gradient {
    display: block;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5vw;
    background: linear-gradient(180deg, transparent 0, #232627 100%);
}

.MuiBox-root .css-19kzrtu{
  padding: 0;
}